import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role';

export const User = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const allData = [];
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle(intl.formatMessage({ id: "109001", defaultMessage: "User Management" }));
  const { authToken } = useSelector((state) => state.auth);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [roleList, setRoleList] = useState([]);

  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('edit')
    checkAuth()

  }, []);

  const checkAuth =async()=> {
    
    const data = await roleCheck('USER')
    setRoleList(data)
    console.log(data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }
    let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
    setCampaigns_id_headder(campaigns_id_headders);

    let value = 1
    if (localStorage.getItem("userPage") != null) {
      value = localStorage.getItem("userPage")
    }
    getUser(value, 10, search, campaigns_id_headders);
  }

  const getUser = async (page, limit, search, campaigns_id_headders) => {
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/api/v1/user/list?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            role_id: data.role_id,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
            updated_at: moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss"),
          };
          allData.push(data);
        });
      }

      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setUserData(allData);
    });
  };

  const checkNull = (data) => {
    if (data) {
      return data
    }
    return intl.locale == 'th' ? 'ไม่มีข้อมูล' : 'No Data'
  }

  const columns = [
    // {
    //   dataField: "id",
    //   text: intl.formatMessage({ id: "109030" ,defaultMessage:"ID"}),
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "firstname",
      text: intl.formatMessage({ id: "109031", defaultMessage: "Firstname" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lastname",
      text: intl.formatMessage({ id: "109031", defaultMessage: "Lastname" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "109032", defaultMessage: "USERNAME" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "109033", defaultMessage: "Create At" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "109033", defaultMessage: "Update At" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: intl.formatMessage({ id: "109034", defaultMessage: "default content" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {roleList.includes('EDIT') && <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              {intl.formatMessage({ id: "109004", defaultMessage: "edit" })}
            </button>}

            {roleList.includes('DELETE') && <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              {intl.formatMessage({ id: "109005", defaultMessage: "delete" })}
            </button>}
          </div>
        );
      },
    },
  ];
  const editItem = (data) => {
    history.push("/EditUser", data);
  };

  const deleteItem = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: intl.formatMessage({ id: "113107", defaultMessage: "Are you sure?" }),
      text: intl.formatMessage({ id: "113108", defaultMessage: "You will not be able to recover this template !" }),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113109", defaultMessage: "Yes, delete it!" }),
      cancelButtonText: intl.formatMessage({ id: "113110", defaultMessage: "No, keep it" }),
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/api/v1/user/${data.row.id}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
          .then((res) => {
            Swal.fire({
              title: intl.formatMessage({ id: "113111", defaultMessage: "Deleted!" }),
              text: intl.formatMessage({ id: "113112", defaultMessage: "Your template has been deleted." }),
              icon: "success",
              confirmButtonText: intl.formatMessage({ id: "113116", defaultMessage: "ok" }),

            }
            );
            getUser(1, limit, search, campaigns_id_headder);
          })
          .catch((err) => {
            Swal.fire({
              title: intl.formatMessage({ id: "113111", defaultMessage: "Deleted!" }),
              text: intl.formatMessage({ id: "113113", defaultMessage: "Your template has been fail to deleted." }),
              icon: "error",
              confirmButtonText: intl.formatMessage({ id: "113116", defaultMessage: "ok" }),

            }
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: intl.formatMessage({ id: "113114", defaultMessage: "Cancelled" }),
          text: intl.formatMessage({ id: "113115", defaultMessage: "Your template is safe :)" }),
          icon: "error",
          confirmButtonText: intl.formatMessage({ id: "113116", defaultMessage: "ok" }),
        }
        );
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getUser(1, limit, search, campaigns_id_headder);
  };

  const handlePageChange = (event, value) => {
    setPage(value);

    localStorage.setItem("userPage", value)

    getUser(value, limit, search, campaigns_id_headder);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getUser(1, event.target.value, search, campaigns_id_headder);
  };

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "109001", defaultMessage: "User Management" })}>
        <CardHeaderToolbar>
          <Link to="/CreateUser">
            {roleList.includes('CREATE') &&<button type="button" className="btn btn-primary">
              {intl.formatMessage({ id: "107009", defaultMessage: "Create" })}
            </button>}
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder={intl.formatMessage({ id: "109002", defaultMessage: "Text Search" })}
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                {intl.formatMessage({ id: "109003", defaultMessage: "search" })}
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={userData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {intl.formatMessage({ id: "109035", defaultMessage: "Item per page" })}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={localStorage.getItem("userPage") != null ? Number(localStorage.getItem("userPage")) : page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
