import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { baseURL } from "../../../service/API.js";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import "../component.css";
import { temp_store } from "../../../modules/function/temp_store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const EditUser = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "109006", defaultMessage: "Edit User management" })
  );
  const history = useHistory();
  const [id, setId] = useState();
  const [firstname, setfirstname] = useState("");
  const [firstnameEn, setfirstnameEn] = useState("");
  const [lastname, setlastname] = useState("");
  const [lastnameEn, setlastnameEn] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [openCheck, setOpenCheck] = useState(false);
  const { authToken } = useSelector((state) => state.auth);
  const [state, setState] = useState(false);
  const [role, setRole] = useState(null);
  const [roleList, setRoleList] = useState([]);
  useEffect(() => {
    console.log(props);
    getList();

    setId(temp_store(props).location.state.row.id);
    setfirstname(temp_store(props).location.state.row.firstname);
    setlastname(temp_store(props).location.state.row.lastname);
    setEmail(temp_store(props).location.state.row.email);
    setFullname(temp_store(props).location.state.row.fullname);
    setState(temp_store(props).location.state.row.is_active ? true : false);
  }, []);

  const getList = async () => {
    //setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/api/v1/role?page=${1}&limit=${10000}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        console.log(res.data.data);
      }
      setRoleList(res.data.data);
      setRole(res.data.data.find((x) => x.id == temp_store(props).location.state.row.role_id))
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // await setSubmitting(true);
    console.log("inn");
    var data = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      role_id: role.id
    };

    if (data.firstname && data.lastname && data.email) {
      console.log(authToken);
      let apiUrls = `${baseURL}/api/v1/user/edit/${id}`;
      axios({
        method: "put",
        url: apiUrls,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        data: data,
      })
        .then((res) => {
          Swal.fire({
            title: intl.formatMessage({
              id: "113101",
              defaultMessage: "Save !",
            }),
            text: intl.formatMessage({
              id: "113102",
              defaultMessage: "Update user success",
            }),
            icon: "success",
            confirmButtonText: intl.formatMessage({
              id: "113116",
              defaultMessage: "ok",
            }),
          });
          history.goBack();
        })
        .catch((err) => {
          // console.log(err);
          Swal.fire({
            title: intl.formatMessage({
              id: "113101",
              defaultMessage: "Save !",
            }),
            text:
              intl.formatMessage({
                id: "113104",
                defaultMessage: "Your can not update user. !! ",
              }) + err,
            icon: "error",
            confirmButtonText: intl.formatMessage({
              id: "113116",
              defaultMessage: "ok",
            }),
          });
        });
    } else {
      Swal.fire({
        title: intl.formatMessage({ id: "113101", defaultMessage: "Save !" }),
        text: intl.formatMessage({
          id: "113104",
          defaultMessage: "Your can not update user. !! ",
        }),
        icon: "error",
        confirmButtonText: intl.formatMessage({
          id: "113116",
          defaultMessage: "ok",
        }),
      });
    }
  };
  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "109006",
            defaultMessage: "User management",
          })}
        ></CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              email: temp_store(props).location.state.row.email,
              firstname: temp_store(props).location.state.row.firstname,
              lastname: temp_store(props).location.state.row.lastname,
            }}
            validate={(values) => {
              let errors = {};

              console.log(
                values.password?.length < 6,
                values.password?.length > 12,
                values.password?.length < 6 || values.password?.length > 12
              );
              if (!values.firstname)
                errors.firstname = intl.formatMessage({
                  id: "109012",
                  defaultMessage: "Firstname is required",
                });
              if (!values.lastname)
                errors.lastname = intl.formatMessage({
                  id: "109014",
                  defaultMessage: "lastname is required",
                });
              if (!values.email)
                errors.email = intl.formatMessage({
                  id: "109016",
                  defaultMessage: "email ",
                });
              //check if my values have errors
              return errors;
            }}
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <Form>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "109007",
                        defaultMessage: "firstname",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="text"
                      name="firstname"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "109007",
                        defaultMessage: "firstname",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="firstname">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "109009",
                        defaultMessage: "lastname",
                      })}{" "}
                      :{" "}
                    </p>
                    <Field
                      type="text"
                      name="lastname"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "109009",
                        defaultMessage: "lastname",
                      })}
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="lastname">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "113065",
                        defaultMessage: "email",
                      })}
                    </p>
                    <Field
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="email"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="email">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="form-inline mb-5">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      {intl.formatMessage({
                        id: "101006",
                        defaultMessage: "Role",
                      })}{" "}
                      :{" "}
                    </p>

                    <Autocomplete
                      //multiple
                      id="grouped-demo"
                      options={roleList}
                      //groupBy={(option) => option.topic_name}
                      getOptionLabel={(option) => option.role}
                      getOptionSelected={(option, value) =>
                        option.role_id === value.role_id
                      }
                      value={role}
                      onChange={(event, newValue) => {
                        setRole(newValue);
                      }}
                      style={{
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </div>

                  {/*} <div className="form-inline mb-5">
                      <a
                        style={{
                          textDecoration: "#007BFF underline",
                          color: "#007BFF",
                        }}
                        data-toggle="modal"
                        data-target="#modal-EditPassword"
                        onClick={() => setOpenCheck(!openCheck)}
                      >
                        {openCheck ? intl.formatMessage({ id: "113093",defaultMessage:"cancel change password" })
                        : intl.formatMessage({ id: "113068",defaultMessage:"change password" })}
                      </a>
                  </div>*/}

                  {/*openCheck ? (
                    <div>
                      <div className="form-inline">
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({ id: "109011" ,defaultMessage:"password"})} :{" "}
                        </p>
                        <Field
                          type="text"
                          name="password"
                          className="form-control"
                          maxLength={12}
                          placeholder={intl.formatMessage({ id: "109011" ,defaultMessage:"password"})}
                          style={{
                            height: "38px",
                            width: "530px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                          }}
                        />
                      </div>
                      <div className="form-inline">
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        ></p>
                        <ErrorMessage className="colorError" name="password">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="form-inline">
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({ id: "113067",defaultMessage:"Confirm Password" })} :{" "}
                        </p>
                        <Field
                          type="text"
                          name="confirmPassword"
                          className="form-control"
                          maxLength={12}
                          placeholder={intl.formatMessage({ id: "113067",defaultMessage:"Confirm Password" })}
                          style={{
                            height: "38px",
                            width: "530px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                          }}
                        />
                      </div>
                      <div className="form-inline">
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        ></p>
                        <ErrorMessage className="colorError" name="confirmPassword">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  ) : ('')*/}

                  <CardHeaderToolbar>
                    <div
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></div>
                    <Link to="/User">
                      <button type="button" className="btn btn-secondary wbt">
                        {intl.formatMessage({
                          id: "109017",
                          defaultMessage: "cancel",
                        })}
                      </button>
                    </Link>
                    <button
                      className="btn btn-primary wbt"
                      type="submit"
                      disabled={formProps.isSubmitting}
                    >
                      {intl.formatMessage({
                        id: "109018",
                        defaultMessage: "save",
                      })}
                    </button>
                  </CardHeaderToolbar>
                </Form>
              );
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};
