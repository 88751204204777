import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
//import { privilegeURL, privilegeURLV2, campaigns_id_privilage } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import { baseURLV1, privilegeURL2, privilegeURLV2, campaigns_id_privilage } from "../service/API.js";
import { roleCheck } from '../service/role';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export const PrivilegeSettingToYou = () => {
  const suhbeader = useSubheader();
  const classes = useStyles();
  const allData = [];
  const [event, setEvent] = useState([]);
  const [privilegeSetting, setPrivilegeSettingData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  const [campaign_id, setCampaignId] = useState(1);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const { user } = useSelector((state) => state.auth);

  suhbeader.setTitle("Privilege Setting");
  const { authToken, accessTokenPrivilege } = useSelector((state) => state.auth);
  const history = useHistory();
  useEffect(() => {
    localStorage.setItem("quotaCampaignData", JSON.stringify([]))
    localStorage.setItem("quotaMemberData", JSON.stringify([]))
    //setCampaigns_id_headder(campaigns_id_privilage);
    //getPrivilegeSetting(page, limit, search);
    checkAuth()
  }, []);
  const [roleList, setRoleList] = useState([]);
  const checkAuth =async()=> {
    
    const data = await roleCheck('PRIVILEGE')
    setRoleList(data)
    console.log(data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }

    let value = 1
    if (localStorage.getItem("userPage") != null) {
      value = localStorage.getItem("userPage")
    }
    getPrivilegeSetting(page, limit, search);
  }

  const getPrivilegeSetting = async (page, limit, search) => {
    let token = localStorage.getItem("accessTokenPrivilege");
    setPage(page);
    axios({
      method: "get",
      url: `${privilegeURLV2}/deal?campaign_id=${campaigns_id_privilage}&is_count=0&page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res)
      if (res.message) {
        //history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            created_date: data.created_date,
            dealTags: data.dealTags ? data.dealTags : [],
            dealRules: data.dealRules ? data.dealRules : [],
            deal_active: data.deal_active,
            is_lucky: data.is_lucky,
            is_show_on_front: data.is_show_on_front,
            deal_display_weight: data.deal_display_weight,
            campaign_id: data.id,
            deal_name: data.deal_name,
            deal_name_en: data.deal_name_en,
            deal_name_th: data.deal_name_th,
            deal_tags: data.deal_tags ? data.deal_tags : [],
            deal_expire_min: data.deal_expire_min,
            point: data.point,
            price: data.price ? data.price : 0,
            is_delivery: data.is_delivery,
            deal_detail_promote: data.deal_detail_promote,
            deal_detail_short: data.deal_detail_short,
            deal_detail_short_th: data.deal_detail_short_th,
            deal_detail_condition: data.deal_detail_condition,
            deal_detail_condition_en: data.deal_detail_condition_en,
            deal_logo: data.deal_logo,
            deal_image: data.deal_image,
            updated_date: data.updated_date,
            deal_logo: data.deal_logo,
            deal_detail_location: data.deal_detail_location,
            step_deal: data.step_deal,
            actualCodeRemaining: data.actualCodeRemaining,
            deal_total:data.deal_total,
            deal_used:data.deal_used

          

          };
          allData.push(data);
        });
        setEvent(res.data.data)
        console.log(allData)
      }
      allData.sort((b, a) => {
        const nameA = new Date(a.created_date)
        const nameB = new Date(b.created_date)
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })

      var totalpage = Math.ceil(res.data.total / res.data.perPage);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.perPage);
      setPrivilegeSettingData(allData/*.sort((b,a) => {
        const nameA = new Date(a.updated_date) 
        const nameB = new Date(b.updated_date) 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      } )*/);
      console.log(allData.sort((b, a) => {
        const nameA = new Date(a.created_date)
        const nameB = new Date(b.created_date)
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }))
      console.log(allData[0].created_date)
    }).catch((err) => {
      //window.location.assign("/logout");
    });
  };

  const columns = [
    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <>
            {roleList.includes('EDIT') && <div className="mt-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => editItem({ id, row, rowIndex })}
              >
                Edit
              </button>
            </div>}
            {/*<div className="mt-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => viewItem({ id, row, rowIndex })}
              >
                View
              </button>
            </div>*/}
          </>
        );
      },
    },
    {
      dataField: "deal_image",
      text: "Privilege Image",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            <img
              src={row.deal_image}
              style={{ width: "100px", height: "auto" }}
            />
          </div>
        );
      },
    },
    {
      dataField: "deal_name",
      text: "Privilege Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            {row.deal_name}
          </div>
        );
      },
    },
    {
      dataField: "deal_detail_short",
      text: "Short Detail",
      sort: true,

      //sortCaret: sortCaret,
      //headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "200px" }}>
            <td dangerouslySetInnerHTML={{__html: row.deal_detail_short}} />
            
          </div>
        );
      },
    },
    {
      text: "Tag",
      formatter: (id, row, rowIndex) => {
        return (
          <FormControl className={classes.formControl}>
            <div className={classes.chips}>
              {
                row.dealTags.map((data, index) => (
                  <Chip
                    key={index}
                    label={data.tag ? data.tag.topic.tag_topic_name + " - " + data.tag.tag_name : null}
                    className={classes.chip}
                  />
                ))
              }
            </div>
          </FormControl>
        );
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Start Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "120px" }}>
            <p>
              {row.dealRules.length > 0
                ?
                row.dealRules.map((dealRules_data, key) => {
                  if (dealRules_data.type == "redeem" || dealRules_data.type == "redeem_period") {
                    return moment(dealRules_data.start_date).format('YYYY-MM-DD HH:mm:ss')
                  }
                })
                : null}
            </p>
          </div>
        );
      },
    },
    {
      text: "End Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "120px" }}>
            <p>
              {row.dealRules.length > 0
                ?
                row.dealRules.map((dealRules_data, key) => {
                  if (dealRules_data.type == "redeem" || dealRules_data.type == "redeem_period") {
                    return moment(dealRules_data.end_date).format('YYYY-MM-DD HH:mm:ss')
                  }
                })
                : null}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "deal_active",
      text: "Active",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "120px" };
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "120px" }}>
            <p>
              {row.deal_active ? 'Active' : 'Not Active'}
            </p>
          </div>
        );
      },
    },

    // {
    //   dataField: "deal_display_weight",
    //   text: "Display number",
    //   sort: true,

    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   formatter: (id, row, rowIndex) => {
    //     return (
    //       <div style={{ width: "150px" }}>
    //         <p>
    //           {row.deal_display_weight}
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "deal_total",
      text: "Deal Total",
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            <p>
              {row.deal_total}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "deal_used",
      text: "Deal Used",
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            <p>
              {row.deal_used}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "actualCodeRemaining",
      text: "CodeRemaining",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            <p>
              {row.actualCodeRemaining}
            </p>
          </div>
        );
      },
    },
    
    {
      dataField: "created_date",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: "150px" }}>
            <p>
              {row.created_date ? moment(row.created_date).format('DD/MM/YYYY') : ''}
            </p>
          </div>
        );
      },
    },
    
  ];


  const viewItem = (data) => {
    const rows = event.find((e) => e.id == data.id)

    history.push("/DHLtoyou/ViewPrivilegeSettingToYou", data.row);
  };

  const editItem = (data) => {
    const rows = event.find((e) => e.id == data.id)

    history.push("/EditPrivilegeSettingToYou", data.row);
  };

  /*const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this template !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${privilegeURL}/PrivilegeSetting/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your template has been deleted.", "success");
            getPrivilegeSetting(page, limit, search, campaigns_id_headder);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your template has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your template is safe :)", "error");
      }
    });
  };*/
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getPrivilegeSetting(1, limit, search, campaigns_id_headder);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getPrivilegeSetting(value, limit, search, campaigns_id_headder);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getPrivilegeSetting(1, event.target.value, search, campaigns_id_headder);
  };
  const path = window.location.pathname
  return (
    <Card>
      <CardHeader title="Privilege Setting">
        <CardHeaderToolbar>
          {roleList.includes('CREATE') &&<Link to={"/CreatePrivilegeSettingToYou"}>
            <button type="button" className="btn btn-primary">   + Create
            </button>
          </Link>}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={privilegeSetting}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6  d-flex align-items-center">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <span class="mt-3 ml-5">Result : {totalSize} records</span>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card >
  );
};
