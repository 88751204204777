import { baseURL } from "../service/API.js";
import axios from "axios";
export const roleCheck = async (page) => {
    let result = [];
    let token = localStorage.getItem("Token");
    await axios({
        url:`${baseURL}/api/v1/role/check`,
        method: 'POST',
        data: {
            page_name: page,
        },
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => {
            res.data.data.map(item=>{
                result.push(item.type)
            })

        })
        .catch((error) => {
            console.error('Error:', error);
        });

    
    return result;
}


