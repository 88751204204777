import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls'
import { useSubheader } from '../../_metronic/layout'
import { baseURLV1 } from '../service/API.js'
import BootstrapTable from 'react-bootstrap-table-next'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import Chart from 'react-apexcharts'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import "./style.css"
import TableauReport from 'tableau-react';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 3
  },
  papers: {
    padding: theme.spacing(2),
    marginBottom: '20px',
    marginTop: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginBottom: '20px',
    marginTop: '20px',
    maxWidth: '500px'
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}))

export const DashboardPage = (props) => {
  const options = {
    height: 1000,
    width: 1100,
    hideTabs: false,
  };

  const filters = {
    Colors: ['Blue', 'Red'],
    Sizes: ['Small', 'Medium']
  };

  const parameters = {
    Param1: 'Value',
    Param2: 'Other Value'
  };
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  var d = new Date()
  d.setDate(d.getDate() - 30)
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [chart, setChart] = useState([])
  const [tableData, setTableData] = useState([])
  const [tableCol, setTableCol] = useState([])

  const classes = useStyles()
  suhbeader.setTitle('Dashboard Page')
  useEffect(() => {
    var dataPie = {
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    getPieChart(dataPie)
  }, [])
  const [checkData, setCheckData] = useState(false)
  const [barState, setBarState] = useState()
  const [barState2, setBarState2] = useState()
  const [barState3, setBarState3] = useState()
  const [pieState, setPieState] = useState()

  const handleSearch = () => {
    setChart([])
    setTableData([])
    var dataPie = {
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    getPieChart(dataPie);
  };

  const getPieChart = data => {
    axios
      .get(
        `${baseURLV1}/dashboard/render?dateFrom=${data.date_from}&dateTo=${data.date_to}`
      )
      .then(res => {
        setPieState()
        setBarState()
        var count = 0
        var count_data = []
        var item_table = []
        if (res.data.length > 0) {
          res.data.map((re_data, i) => {
            console.log(re_data.type)
            if (re_data.type === 'summary') {
              var option = {
                type: 'summary',
                boxBackgroundColor: re_data.boxBackgroundColor,
                boxBackgroundType: re_data.boxBackgroundType,
                boxSize: re_data.boxSize,
                title: re_data.title,
                titleFontSize: re_data.titleFontSize,
                titleFontColor: re_data.titleFontColor,
                dataFontSize: re_data.dataFontSize,
                dataFontColor: re_data.dataFontColor,
                data: re_data.data,
                titleIcon: re_data.titleIcon
              }
              count_data.push(option)
            } else {
              if (re_data.subType === 'pie') {
                var key = []
                var value = []

                re_data.data.map(data => {
                  value.push(Number(data.total))
                  key.push(data.name)
                })

                var option = {
                  series: value,
                  type: 'pie',
                  boxBackgroundColor: re_data.boxBackgroundColor,
                  boxBackgroundType: re_data.boxBackgroundType,
                  boxSize: re_data.boxSize,
                  options: {
                    colors: ['#E98C23', '#E12441', '#4D7AB6', '#2A2458', '#3E9C48', '#B5C8A4', '#592568', '#FEF9C4', '#F7B555', '#A41C32', '#D3242C', '#F17B20',],
                    title: {
                      text: re_data.title ? re_data.title : null
                    },
                    chart: {
                      toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                          download: true
                        },
                        export: {
                          svg: {
                            filename: 'undefined',
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                              return new Date(timestamp).toDateString()
                            }
                          }
                        },
                        autoSelected: 'zoom'
                      },
                      width: 380,
                      type: 'pie'
                    },
                    labels: key,
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'bottom'
                          }
                        }
                      }
                    ]
                  }
                }

                count_data.push(option)
              } else if (re_data.subType === 'bar') {
                var item = []

                re_data.data.map(data => {
                  item.push({
                    data: [{ x: data.name, y: data.total }],
                    name: data.name,
                    group: data.group ? data.group : "Group"
                  })
                })
                var option = {
                  type: 'bar',
                  colors: ['#F44336', '#E91E63', '#9C27B0'],
                  boxBackgroundColor: re_data.boxBackgroundColor,
                  boxBackgroundType: re_data.boxBackgroundType,
                  boxSize: re_data.boxSize,
                  series: item,
                  options: {
                    colors: ['#E98C23', '#E12441', '#4D7AB6', '#2A2458', '#3E9C48', '#B5C8A4', '#592568', '#FEF9C4', '#F7B555', '#A41C32', '#D3242C', '#F17B20'],
                    title: {
                      text: re_data.title ? re_data.title : null
                    },
                    chart: {
                      type: 'bar',
                      toolbar: { tools: { download: true } },
                      height: 350
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false
                      }
                    },
                    labels: item.map((data) => { return data.group }),
                    xaxis: {
                      type: 'category',
                      labels: {
                        show: false,
                      },
                      title: {
                        text: re_data.title ? re_data.title : null
                      }
                    }
                  }
                }
                count_data.push(option)
              }
              else if (re_data.subType === 'line') {
                var item = []
                var itemX = []

                re_data.data.map(data => {
                  item.push(data.total)
                  itemX.push(data.name)
                })

                var option = {
                  type: "line",
                  boxSize: re_data.boxSize,
                  options: {
                    title: {
                      text: re_data.title ? re_data.title : null
                    },
                    xaxis: {
                      categories: itemX,
                      tickPlacement: 'between',
                      title: {
                        text: re_data.title ? re_data.title : null
                      },
                      tickAmount: 14
                    },
                    chart: {
                      offsetX: 0
                    }
                  },
                  series: [{
                    name: re_data.title ? re_data.title : null,
                    data: item
                  }],
                };
                count_data.push(option)
              }
              else if (re_data.type === 'table') {
                var keys = []
                var newState = []
                if (re_data.data.length > 0) {
                  keys = Object.keys(re_data.data[0])
                  keys.map(async key => {
                    if (key === "Image") {
                      newState.push({
                        dataField: key,
                        text: key,
                        sort: true,
                        sortCaret: sortCaret,
                        headerSortingClasses,
                        headerStyle: (colum, colIndex) => {
                          return { width: "100px" };
                        },
                        formatter: (id, row, rowIndex) => {
                          return (
                            <div>
                              <img
                                src={row.Image}
                                style={{ width: "100px", height: "auto" }}
                              />
                            </div>
                          );
                        },
                      })
                    } else {
                      newState.push({
                        dataField: key,
                        text: key,
                        sort: true,
                        headerStyle: (colum, colIndex) => {
                          return { width: "100px" };
                        },
                        sortCaret: sortCaret,
                        headerSortingClasses
                      })
                    }
                  })
                  item_table.push([{ data: re_data.data }, { col: newState }, { title: re_data.title }])
                }
              }
            }
          })
        }
        setChart(count_data)
        setTableData(item_table)

      })
      .catch(err => {
        setCheckData(true)
        console.log(err)
      })
  }
  const handleChangeDateFrom = date => {
    setDateFrom(date)
  }
  const handleChangeDateTo = date => {
    setDateTo(date)
  }
  return (
    <Card>
      <CardHeader title="Dashboard"></CardHeader>
      <CardBody>
        <TextField
          id="DateFrom"
          type="date"
          label="Date From"
          defaultValue={dateFrom}
          className=" mb-3 mr-3"
          style={{
            height: '38px',
            width: '185px',
            backgroundColor: '#F4F6F9',
            borderRadius: '5px',
            borderWidth: '0'
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={name => {
            handleChangeDateFrom(name.target.value)
          }}
        />
        <TextField
          id="DateFrom"
          type="date"
          label="Date To"
          defaultValue={dateTo}
          className=" mb-3 mr-3"
          style={{
            height: '38px',
            width: '185px',
            backgroundColor: '#F4F6F9',
            borderRadius: '5px',
            borderWidth: '0'
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={name => {
            handleChangeDateTo(name.target.value)
          }}
        />
        <button
          type="button"
          id="button"
          className="btn btn-primary mb-3 mr-3 ml-3 mt-1"
          onClick={() => handleSearch()}
        >
          Search
        </button>
        {
          checkData ?
            <div className="row">
              <div style={{
                position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
              }}>
                {/* <h1>Dashboard No Data</h1> */}
              </div>
            </div>
            :
            chart.length > 0 ? null :
              <div className="row">
                <div style={{
                  position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
                }}>

                  <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-success spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-info spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-light spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
        }
        <div className="row">
          {chart.map((data, i) => {
            return (
              <>
                {String(data.type) === 'summary' ? (
                  <div className={`${data.boxSize}`}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: `${data.boxBackgroundColor}` }}
                    >
                      <span
                        style={{
                          fontSize: `${data.titleFontSize}`,
                          color: `${data.dataFontColor}`
                        }}
                      >
                        {data.title}
                      </span>
                      <div className="d-flex justify-content-between">
                        <span
                          style={{
                            fontSize: `${data.dataFontSize}`,
                            color: `${data.dataFontColor}`
                          }}
                        >
                          {data.data}
                        </span>
                        <img
                          src={data.titleIcon}
                          style={{
                            width: '50px',
                            height: '50px',
                            textAlign: 'right'
                          }}
                        />
                      </div>

                      <br />
                    </Paper>
                  </div>
                ) :
                  null
                }
              </>
            )
          })}
        </div>

        <div className="row">
          {chart.map((data, i) => {
            return (
              <>
                {String(data.type) === 'summary' ? null : (
                  <div className={`${data.boxSize}`}>
                    <Paper className={classes.papers}>

                      <Chart
                        options={data.options}
                        series={data.series}
                        type={`${data.type}`}
                        height={300}
                      />

                    </Paper>
                  </div>
                )}
              </>
            )
          })}
        </div>
        {tableData.length > 0 ?
          tableData.map((datas, key) => {

            return (
              <Paper className={classes.papers}>
                <CardHeader title={datas[2].title}></CardHeader>
                <BootstrapTable
                  keyField="id"
                  {...props.baseProps}
                  data={datas[0].data}
                  columns={datas[1].col}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                />
              </Paper>
            )
          })
          : null}
        {/*<div>
          <TableauReport
            url="https://prod-apnortheast-a.online.tableau.com/t/feyverlyanalytics/views/AXA_prototype/Story2"
            filters={filters}
            parameters={parameters}
            options={options} // vizCreate options
            // Overwrite default query params
            // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
            query="?:origin=card_share_link&:embed=n"
          />
        </div>*/}


      </CardBody>
    </Card>
  )
}
