import axios from 'axios'
import { baseURLV1 } from '../../../service/API'

export const LOGIN_URL = `${baseURLV1}/auth/login`
export const ME_URL = `${baseURLV1}/auth/me`;
export function login(email, password) {
  localStorage.setItem("email", email)
  localStorage.setItem("pass", password)
  return axios.post(LOGIN_URL, { email, password })
}

export function getUserByToken() {
  let token = localStorage.getItem("Token");
  return axios({
    method: "post",
    url: ME_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}
