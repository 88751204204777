import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURLV1, baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { roleCheck } from '../service/role';
export const Banner = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [templateData, setTemplateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  const [is_loading, setIs_Loading] = useState(false);
  const [listError, setlistError] = useState(false);
  suhbeader.setTitle("Banner Management");
  const { user } = useSelector((state) => state.auth);
  const [listMasterModel, setListMasterModel] = useState([]);
  const history = useHistory();
  const [roleList, setRoleList] = useState([]);
  const checkAuth =async()=> {
    
    const data = await roleCheck('BANNER')
    setRoleList(data)
    console.log(data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }

    let value = 1
    if (localStorage.getItem("userPage") != null) {
      value = localStorage.getItem("userPage")
    }
    getListMasterModel(page, limit);
  }
  useEffect(() => {
    localStorage.removeItem('edit')
    checkAuth();
  }, []);

  const getListMasterModel = async (page, limit) => {
    setIs_Loading(true);
    const apiUrls = `${baseURLV1}/banner?page=${page}&limit=${limit}`; // รอแก้
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    })
      .then((res) => {
          console.log(res.data)
        /*res.data.data.map(item=>{
          item.popupstart = ISOString(item.popupstart)
          item.popupend = ISOString(item.popupend)
        })*/
        setListMasterModel(res.data.data);
        setIs_Loading(false);
        setlistError(false);

        var totalpage = Math.ceil(res.data.total / res.data.limit);
        setTotalPage(totalpage);
        setTotalSize(res.data.total);
        setLimit(res.data.limit);
        setTemplateData(allData);
      })
      .catch((err) => {
        if (err.response) {
          setIs_Loading(false);
          setlistError(true);
        }
      });
  };

  const ISOString = (data) => {
    console.log(`${data.toString().substring(0, 10)} ${data.toString().substring(11, 16)}`)
    return `${data.toString().substring(0, 10)} ${data.toString().substring(11, 16)}`
    // console.log(Date(data).toISOString())
};
  const columns = [

    /*{
      dataField: "id",
      text: "News Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },*/
    {
        text: "image_th",
        dataField: "image",
        formatter: (img, row, rowIndex) => {
            return (
                <div>
                    <img
                        style={{
                            width: "200px",
                        }}
                        src={row.image_th}
                    />
                </div>
            );
        },
    },

    {
      dataField: "banner_category_id",
      text: "banner category id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "link",
      text: "link",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "weight",
      text: "weight",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    /*{
      dataField: "createdAt",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "CreateBy",
      text: "Create By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updatedAt",
      text: "Update Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "UpdateBy",
      text: "Update By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },*/

    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{width:'150px'}}>
            {roleList.includes('EDIT') && <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>}

            {roleList.includes('DELETE') &&<button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>}

          </div>
        );
      },
    },
    /*{
      text: "Image Management",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => ImageManagement({ row, rowIndex })}
            >
              Image
            </button>

          </div>
        );
      },
    },*/
  ];

  const editItem = (data) => {
    history.push("/EditBanner", data);
  };

  const ImageManagement = (data) => {
    history.push("/EditNewsImage", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this limit point !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURLV1}/banner/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your model has been deleted.", "success");
            getListMasterModel(page, limit);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your model has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your model is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getListMasterModel(1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getListMasterModel(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getListMasterModel(1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Banner Management">
        <CardHeaderToolbar>
          <Link to="/CreateBanner">
            {roleList.includes('CREATE') &&<button type="button" className="btn btn-primary">
              + Create
            </button>}
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>


        {/* /Search Btn/ */}

        {/* <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div> */}


        <BootstrapTable
          keyField="id"
          data={listMasterModel}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
