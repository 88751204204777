import React from 'react';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './CanvasUtils'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CropperImage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            crop: { x: 0, y: 0 },
            zoom: 1,
            cropPixels: null,
            open: true,
            aspect: this.props.scale
        }
    }
    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ cropPixels: croppedAreaPixels })
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }
    componentWillMount() {
        this.setState({ image: this.props.image, original: this.props.original })
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleCrop = async () => {
        const { image, cropPixels } = this.state
        this.setState({ image: null, open: false })
        const croppedImage = await getCroppedImg(image, cropPixels)
        this.props.handleUpload(croppedImage);
    };

    handleClose = async () => {
        this.setState({ image: null, open: false })
        this.props.handleCancel();
    };

    render() {
        return (
            <React.Fragment >

                <Dialog fullScreen open={this.state.open} TransitionComponent={Transition}>
                    <AppBar className="appBar">
                        <Toolbar>
                            <Typography variant="h6" className="title">
                                Crop
                                </Typography>
                            <Button color="inherit" onClick={this.handleCrop}>
                                Crop Image
                                </Button>
                            <Button color="inherit" onClick={this.handleClose}>
                                Cancel
                                </Button>
                        </Toolbar>
                    </AppBar>

                    <Cropper
                        className="croperImage"
                        image={this.props.image}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />

                </Dialog>
            </React.Fragment >
        )
    }
}

export default CropperImage;
