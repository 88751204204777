const temp_store = (props) => {
    // localStorage.removeItem('edit')
    const check = localStorage.getItem('edit')
    //console.log('m',check)
    //console.log(localStorage.getItem('edit'))

    if (!check) {
      //console.log('in')
      localStorage.setItem("edit", JSON.stringify(props));
      //console.log(localStorage.getItem('edit'))
      return props
    } else {
      const storeData = JSON.parse(check)
      // setTemp(storeData)
      //console.log('storeData')
      return storeData
    }

  }

module.exports = {temp_store}