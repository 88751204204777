import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURLV1, baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { roleCheck } from '../service/role';
export const Participants = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [templateData, setTemplateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  const [is_loading, setIs_Loading] = useState(false);
  const [listError, setlistError] = useState(false);
  const [id, setID] = useState(0);
  const [show, setShow] = useState(false);
  suhbeader.setTitle("Participants Management");
  const { user } = useSelector((state) => state.auth);
  const [listMasterModel, setListMasterModel] = useState([]);
  const [mobileNumber, setMobileNumber] = useState('')
  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('edit')
    checkAuth()
  }, []);
  const [roleList, setRoleList] = useState([]);
  const checkAuth =async()=> {
    
    const data = await roleCheck('PARTICIPANT')
    setRoleList(data)
    console.log(data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }

    let value = 1
    if (localStorage.getItem("userPage") != null) {
      value = localStorage.getItem("userPage")
    }
    getListMasterModel(page, limit);
  }
  const handleClose = async () => {
    setShow(false)
  }
  const onSubmit = async (value) => {
    console.log(value)
    /*if (value.mobile_number.length == 10) {
      value.mobile_number = value.mobile_number.slice(1, 10)
    }*/
    let temp = value.mobile_number.length == 10 ? value.mobile_number : `0${value.mobile_number}`
    if (value.mobile_number.length == 10) { 
      value.mobile_number  = value.mobile_number 
    } else {
      value.mobile_number  = `0${value.mobile_number}`
    }
    console.log('x',value )
    const apiUrls = `${baseURLV1}/participant/${id}`; // รอแก้
    let event = value
    if (!value.mobile_number) {
      return Swal.fire("", "you must fill mobile numer.", "warning");

    }
    if (temp.length < 10) {
      return Swal.fire("", "mobile number has 10 digit.", "warning");

    }
    if (temp.length > 10) {
      return Swal.fire("", "mobile number has 10 digit.", "warning");

    }
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: event
    })
      .then((res) => {
        Swal.fire("", "edit success.", "success");
        setID(0)
        setShow(false)
        getListMasterModel(page, limit);
        setMobileNumber('')
      })
      .catch((err) => {
        if (err.response) {
          setShow(false)
        }
      });
  }
  const getListMasterModel = async (page, limit, search = '') => {
    setIs_Loading(true);
    //console.log(search.charAt(0), search.charAt(0) == '0')
    /*if (search.length > 1 && search.charAt(0) == '0' && search.charAt(1) != '0' && search.charAt(2) != '0' && search.charAt(3) != '0') {
      console.log(search.charAt(0))
      search = search.replace('0','')
    }*/
    const apiUrls = `${baseURLV1}/participant?page=${page}&limit=${limit}${search != '' ? `&search=${search}` : ''}`; // รอแก้
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    })
      .then((res) => {
        console.log(res.data)
        res.data.data.map(item => {
          if (!item.mobile_number) {
            item.mobile_number = ''
          }

        })
        setListMasterModel(res.data.data);
        setIs_Loading(false);
        setlistError(false);

        var totalpage = Math.ceil(res.data.total / res.data.limit);
        setTotalPage(totalpage);
        setTotalSize(res.data.total);
        setLimit(res.data.limit);
        setTemplateData(allData);
      })
      .catch((err) => {
        if (err.response) {
          setIs_Loading(false);
          setlistError(true);
        }
      });
  };

  const ISOString = (data) => {
    console.log(`${data.toString().substring(0, 10)} ${data.toString().substring(11, 16)}`)
    return `${data.toString().substring(0, 10)} ${data.toString().substring(11, 16)}`
    // console.log(Date(data).toISOString())
  };
  const columns = [

    {
      dataField: "firstname",
      text: "firstname",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (img, row, rowIndex) => {
        return (
          <div>
            <p style={{ width: 100 }}>
              {row.firstname}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "lastname",
      text: "lastname",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (img, row, rowIndex) => {
        return (
          <div>
            <p style={{ width: 100 }}>
              {row.lastname}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (img, row, rowIndex) => {
        return (
          <div>
            <p style={{ width: 200 }}>
              {row.email}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "mobile",
      text: "mobile",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (img, row, rowIndex) => {
        return (
          <div>
            <p style={{ width: 180 }}>
              {row.mobile_number.length == 10 ? row.mobile_number : `0${row.mobile_number}`}
            </p>
          </div>
        );
      },
    },

    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '150px' }}>
            {roleList.includes('EDIT') &&<button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem2({ id, row, rowIndex })}
            >
              Edit
            </button>}

            {/*<button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>*/}

          </div>
        );
      },
    },
    /*{
      text: "Image Management",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => ImageManagement({ row, rowIndex })}
            >
              Image
            </button>

          </div>
        );
      },
    },*/
  ];

  const editItem = (data) => {
    history.push("/EditBanner", data);
  };

  const editItem2 = (data) => {
    console.log(data)
    setID(data.id)
    setShow(true)
    setMobileNumber(`${data.row.mobile_number}`)
  };

  const ImageManagement = (data) => {
    history.push("/EditNewsImage", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this limit point !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURLV1}/banner/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your model has been deleted.", "success");
            getListMasterModel(page, limit);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your model has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your model is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState('');
  const handleSearch = () => {
    /*if (search.charAt(0) == '0') {
      setSearch(search.slice(0, 1))
    }*/
    setPage(1);
    getListMasterModel(1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getListMasterModel(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getListMasterModel(1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Participants Management">
        <CardHeaderToolbar>
          {/*<Link to="/CreateBanner">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>*/}
        </CardHeaderToolbar>
      </CardHeader>
      <div className="row form-inline ml-4 mt-3">
        <div className="col-lg-4 col-md-4 col-sm-4">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Text Search"
              style={{ width: "100%" }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2">
          <div className="form-group">
            <button
              type="button"
              id="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleSearch()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <CardBody>


        {/* /Search Btn/ */}

        {/* <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div> */}


        <BootstrapTable
          keyField="id"
          data={listMasterModel}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <Modal
          open={show}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5" style={{ marginTop: "30px", marginLeft: "30px", marginRight: "30px" }}>
                <span style={{ fontSize: "20px" }}>Change Mobile Number</span>
                <a
                  href="#"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                  onClick={() => handleClose()}
                >
                  <i className="ki ki-close icon-xs text-muted" />
                </a>
              </div>

              <div className="modal-body">
                <Formik
                  initialValues={{
                    mobile_number: mobileNumber,

                  }}


                  onSubmit={onSubmit}
                  render={(formProps) => {
                    return (
                      <Form className="col">
                        <div className="row">

                          <Field
                            type="number"
                            name="mobile_number"
                            className="form-control"
                            placeholder="New Mobile Number"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>


                        <div className="offcanvas-header d-flex align-items-center justify-content-center pb-5" style={{ marginTop: "30px" }}>
                          <button
                            className="btn btn-danger"
                            type="submit"
                          //disabled={formProps.isSubmitting}
                          >
                            Confirm
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </CardBody>
    </Card>
  );
};
