// CreateNews
import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, baseURLV1, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import TextField from '@material-ui/core/TextField'
import CropperImage from "../CropperImage";
import ImageUploading from "react-images-uploading";
import { Row, Col } from "react-bootstrap";
import ImageResize from "image-resize";
import Resizer from "react-image-file-resizer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const CreateBanner = (props) => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const classes = useStyles();
    suhbeader.setTitle(intl.formatMessage({ id: "###", defaultMessage: "Create Banner" }));
    const history = useHistory();
    const [item_image_path, setItem_image_path] = useState();
    const [item_image_path2, setItem_image_path2] = useState();
    const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
    const [nameImageItem_image_path2, setNameImageItem_image_path2] = useState("");


    
    const { user, setUser } = useState;
    

    const [catagory, setCatagory] = useState()
    const [link, setLink] = useState()
    const [weight, setWeight] = useState()
    const [img1, setIMG1] = useState()
    const [img2, setIMG2] = useState()
    const [is_active, setIs_active] = useState('1');



    let dataTemp = []
    const [images, setImages] = React.useState([]);
    const maxNumber = 10;
    /*const onChange = async (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
        if (imgCount == 0) {
            console.log('x', imgCount)
            setImgCount(imageList.length)
            await Promise.all(imageList.map(async (item, index) => {
                await handleUpload_image_Details(item.file)
            }))
        } else {
            console.log('y', imgCount)

            if (imageList.length > imgCount) {
                await Promise.all(imageList.map(async (item, index) => {
                    if (index > imgCount - 1) {
                        await handleUpload_image_Details(item.file)
                    }
                }))
                setImgCount(imageList.length)

            }
        }

        console.log('img', listImg)
    };*/

    const [stateItem_image_path, setStateItem_image_path] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });

    const [stateItem_image_path2, setStateItem_image_path2] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });


    /*const removeListIMG = (index) => {
        console.log(index)
        let arr = listImg
        arr.splice(index, 1);

        console.log(arr)

        setListImg(arr)
    }*/

    const handleChangeItem_image_path = async (e) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            var reader = new FileReader();
            e.target.value = '';
            handleImageUploadItem_image_path(file)
        }
    };

    const handleChangeItem_image_path2 = async (e) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            var reader = new FileReader();
            e.target.value = '';
            handleImageUploadItem_image_path2(file)
        }
    };


    const removeImageItem_image_path = async () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
        setItem_image_path("");
    };

    const removeImageItem_image_path2 = async () => {
        setStateItem_image_path2({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
        setItem_image_path("");
    };

    const AlertError = (message) => {
        Swal.fire("Alert", message, "error");
    };

    const ISOString = (data) => {
        console.log(`${data.toString().substring(0, 10)} ${data.toString().substring(11, 16)}`)
        return `${data.toString().substring(0, 10)} ${data.toString().substring(11, 16)}`
        // console.log(Date(data).toISOString())
    };



    const handleCancelUploadItem_image_path = () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
    };

    const handleImageUploadItem_image_path = async (file) => {
        const image = await resizeFile(file);
        console.log(image)
        console.log(file)
        imageResize
            .play(URL.createObjectURL(file))
            .then(async function (response) {
                console.log(response)
                const trans = dataURLtoFile(image, 'img.png')
                var formdata = new FormData();
                let token = localStorage.getItem("Token");
                formdata.append("image", trans, URL.createObjectURL(trans));

                var requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                fetch(`${baseURLV1}/upload`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        let res = JSON.parse(result);
                        setStateItem_image_path({
                            previewItem_image_path: null,
                            rawItem_image_path: null,
                            imageURLItem_image_path: null,
                        });
                        let fullPath = `${baseURL}/uploads/${res.filename}`
                        setItem_image_path(fullPath);
                        setIMG1(res.filename);
                        setNameImageItem_image_path(fullPath);
                    })
                    .catch((error) => console.log("error", error));
            })

    };

    const handleImageUploadItem_image_path2 = async (file) => {
        const image = await resizeFile(file);
        console.log(image)
        console.log(file)
        imageResize
            .play(URL.createObjectURL(file))
            .then(async function (response) {
                console.log(response)
                const trans = dataURLtoFile(image, 'img.png')
                var formdata = new FormData();
                let token = localStorage.getItem("Token");
                formdata.append("image", trans, URL.createObjectURL(trans));

                var requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                fetch(`${baseURLV1}/upload`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        let res = JSON.parse(result);
                        setStateItem_image_path2({
                            previewItem_image_path: null,
                            rawItem_image_path: null,
                            imageURLItem_image_path: null,
                        });
                        let fullPath = `${baseURL}/uploads/${res.filename}`
                        setItem_image_path2(fullPath);
                        setIMG2(res.filename);
                        setNameImageItem_image_path2(fullPath);
                    })
                    .catch((error) => console.log("error", error));
            })

    };

    const imageResize = new ImageResize({
        format: "png", // ไฟล์รูปภาพเป็น png
        quality: 0.5, // ปรับขนาดไฟล์รูปภาพให้เหลือครึ่งนึง
        width: 500, // ปรับขนาดรูปให้มีความกว้าง 500 px
    });




    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                3000,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });


    /*const handleUpload_image_Details = async (file) => {
        const image = await resizeFile(file);
        imageResize
            .play(URL.createObjectURL(file)) //แปลงไฟล์เป็น blob
            .then(async function (response) {
                const trans = dataURLtoFile(image, 'img.png')
                var formdata = new FormData();
                let token = localStorage.getItem("Token");
                formdata.append("image", trans, URL.createObjectURL(trans));
                var requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                await fetch(`${baseURLV1}/upload`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        let res = JSON.parse(result);
                        let fullPath = `${baseURL}/uploads/${res.filename}`

                        //setListImg(listImg => [...listImg, fullPath])
                    })
                    .catch((error) => console.log("error", error));
            })
            .catch(function (error) {
                console.error(error);
            });
    };*/

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }


    const onSubmit = async (data) => {
        let img = {}
        if (catagory === undefined || catagory === "") {
            return AlertError("Please insert banner catagory !!");
        }

        if (link === undefined || link === "") {
            return AlertError("Please insert link !!");
        }

        if (weight === undefined || weight === "") {
            return AlertError("Please insert weight !!");
        }

        if (img1 === undefined || img1 === "") {
            return AlertError("Please insert image th!!");
        }

        if (img2 === undefined || img2 === "") {
            return AlertError("Please insert image en!!");
        }
        if (is_active === undefined || is_active === "") {
            return AlertError("Please insert active status !!");
        }



        var events = {
            banner_category_id: catagory,
            link: link,
            weight: weight,

            image_th: img1,
            image_en: img2,
            status: is_active,


        };

        let apiUrls = `${baseURLV1}/banner`;
        let token = localStorage.getItem("Token");
        console.log(events)
        const resultAll = axios({
            method: "post",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                history.goBack();
            })
            .catch((err) => {
                Swal.fire(
                    "Alert",
                    "Your can not create Banner. !! " + err,
                    "error"
                );
            });
    };


    return (
        <>
            <Card>
                <CardHeader title="Create Banner "></CardHeader>
                <CardBody>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "###", defaultMessage: "banner category id" })}:{" "}
                        </p>
                        <input
                            name="topic"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={catagory}
                            onChange={(e) => setCatagory(e.target.value)}
                        />

                    </div>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "###", defaultMessage: "link" })}:{" "}
                        </p>
                        <input
                            name="topic"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                        />

                    </div>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "###", defaultMessage: "weight" })}:{" "}
                        </p>
                        <input
                            name="topic"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                        />

                    </div>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            status
                        </p>
                        <select name="cars" id="cars"
                            value={is_active}
                            onChange={(e) => {
                                setIs_active(e.target.value)
                            }}
                        >
                            <option value="1">true</option>
                            <option value="0">false</option>
                        </select>
                    </div>


                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "###", defaultMessage: "image th" })} : <br></br>

                        </p>
                        <div
                            className="d-flex"
                            style={{
                                width: "530px",
                            }}>
                            <input
                                name="item_image_path"
                                style={{
                                    flexGrow: "1",
                                    height: "38px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={item_image_path}
                            />

                            <span className="btn btn-primary btn-file wbt"
                                style={{
                                    whiteSpace: "nowrap",
                                    width: "fit-content"
                                }}>
                                {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit" })}
                                <input type="file" onChange={handleChangeItem_image_path} />
                            </span>

                            <button
                                type="button"
                                className="btn btn-danger wbt"
                                onClick={removeImageItem_image_path}
                            >
                                {intl.formatMessage({ id: "104026", defaultMessage: "delete" })}
                            </button>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%",
                        }}
                    >
                        {stateItem_image_path.imageURLItem_image_path ? (
                            <img
                                style={{
                                    width: "300px",
                                    height: "300px",
                                }}
                                src={stateItem_image_path.imageURLItem_image_path}
                            />
                        ) : item_image_path ? (
                            <img
                                style={{
                                    width: "300px",
                                    height: "300px",
                                }}
                                src={item_image_path}
                            />
                        ) : null}
                    </div>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "###", defaultMessage: "image en" })} : <br></br>

                        </p>
                        <div
                            className="d-flex"
                            style={{
                                width: "530px",
                            }}>
                            <input
                                name="item_image_path"
                                style={{
                                    flexGrow: "1",
                                    height: "38px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={item_image_path2}
                            />

                            <span className="btn btn-primary btn-file wbt"
                                style={{
                                    whiteSpace: "nowrap",
                                    width: "fit-content"
                                }}>
                                {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit" })}
                                <input type="file" onChange={handleChangeItem_image_path2} />
                            </span>

                            <button
                                type="button"
                                className="btn btn-danger wbt"
                                onClick={removeImageItem_image_path2}
                            >
                                {intl.formatMessage({ id: "104026", defaultMessage: "delete" })}
                            </button>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%",
                        }}
                    >
                        {stateItem_image_path2.imageURLItem_image_path ? (
                            <img
                                style={{
                                    width: "300px",
                                    height: "300px",
                                }}
                                src={stateItem_image_path2.imageURLItem_image_path}
                            />
                        ) : item_image_path2 ? (
                            <img
                                style={{
                                    width: "300px",
                                    height: "300px",
                                }}
                                src={item_image_path2}
                            />
                        ) : null}
                    </div>

                    {/*<ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                        }) => (
                            <div>
                                <div className="form-inline" style={{ marginBottom: "25px" }}>
                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                        {intl.formatMessage({ id: "###", defaultMessage: "Details image" })} : <br></br>

                                    </p>
                                    <div
                                        className="d-flex"
                                        style={{
                                            width: "530px",
                                        }}>


                                        <span className="btn btn-primary btn-file wbt"
                                            style={{
                                                whiteSpace: "nowrap",
                                                width: "fit-content"
                                            }}
                                            onClick={() => {
                                                onImageUpload()
                                            }}
                                            {...dragProps}
                                        >
                                            {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit" })}
                                        </span>

                                        <button
                                            type="button"
                                            className="btn btn-danger wbt"
                                            onClick={onImageRemoveAll}
                                        >
                                            {intl.formatMessage({ id: "104026", defaultMessage: "delete all" })}
                                        </button>


                                    </div>

                                </div>
                                <div className="upload__image-wrapper">

                                    <Row>
                                        {imageList.map((image, index) => (
                                            <Col style={{ marginTop: '20px' }} xs="3">
                                                <div key={index} className="image-item">
                                                    <button className="btnRight" onClick={() => {
                                                        onImageRemove(index)
                                                        console.log(index)
                                                        removeListIMG(index)
                                                    }}>
                                                        <img
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                            }}
                                                            src="/media/icon/close.png" />
                                                    </button>
                                                    <img src={image.data_url} alt="" width="200" />

                                                </div>
                                            </Col>

                                        ))}
                                    </Row>

                                </div>
                            </div>
                        )}
                    </ImageUploading>*/}



                    <Link to="/Banner">
                        <button type="button" className="btn btn-secondary wbt mr-5">
                            {intl.formatMessage({ id: "###", defaultMessage: "Cancel" })}
                        </button>
                    </Link>
                    <button className="btn btn-primary" onClick={() => onSubmit()}>
                        {intl.formatMessage({ id: "###", defaultMessage: "Save" })}
                    </button>
                </CardBody>
            </Card>
        </>
    );
};
