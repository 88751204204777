import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { Report } from "./pages/Report";
import { PrivilegeSettingToYou } from "./pages/PrivilegeSettingToYou";
import { Banner} from "./pages/Banner"
import { CreateBanner } from "./pages/component/Create/CreateBanner";
import { EditBanner } from "./pages/component/Edit/EditBanner";
import { CreatePrivilegeSettingToYou } from "./pages/component/Create/CreatePrivilegeSettingToYou"
import { CreateRole } from "./pages/component/Create/CreateRole"
import { CreateUser } from "./pages/component/Create/CreateUser"
import { EditRole } from "./pages/component/Edit/EditRole"
import { EditUser } from "./pages/component/Edit/EditUser"
import { EditPrivilegeSettingToYou } from "./pages/component/Edit/EditPrivilegeSettingToYou"
import { Participants } from "./pages/Participants";
import { MasterParticipants } from "./pages/MasterParticipants";
import { Role } from "./pages/Role";
import { User } from "./pages/User";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/Dashboard" component={DashboardPage} />
        <ContentRoute path="/Report" component={Report} />
        <ContentRoute path="/PrivilegeSettingToYou" component={PrivilegeSettingToYou} />
        <ContentRoute path="/Banner" component={Banner} />
        <ContentRoute path="/CreateBanner" component={CreateBanner} />
        <ContentRoute path="/EditBanner" component={EditBanner} />
        <ContentRoute path="/CreatePrivilegeSettingToYou" component={CreatePrivilegeSettingToYou} />
        <ContentRoute path="/EditPrivilegeSettingToYou" component={EditPrivilegeSettingToYou} />
        <ContentRoute path="/Participants" component={Participants} />
        <ContentRoute path="/MasterParticipants" component={MasterParticipants} />
        <ContentRoute path="/User" component={User} />
        <ContentRoute path="/Role" component={Role} />
        <ContentRoute path="/CreateRole" component={CreateRole} />
        <ContentRoute path="/CreateUser" component={CreateUser} />
        <ContentRoute path="/EditRole" component={EditRole} />
        <ContentRoute path="/EditUser" component={EditUser} />

        
                
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
