/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../_metronic/_helpers'
import { useIntl } from "react-intl";
import { roleCheckPage } from "../service/rolePage";
import { useHistory } from "react-router-dom";



export function AsideMenuList({ layoutProps }) {
  const [pageList, setPageList] = useState([]);
  const [stock, setStock] = useState(false);
  const checkAuth = async () => {
    const data = await roleCheckPage("MENU");
    setPageList(data);
    console.log(data);
  };
  useEffect(() => {
    console.log("in");
    checkAuth();
    
  }, []);
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {pageList.includes("BANNER") &&<li
          className={`menu-item ${getMenuItemActive('/Banner')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Banner">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Banner</span>
          </NavLink>
        </li>}
        
        {pageList.includes("PRIVILEGE") &&<li
          className={`menu-item ${getMenuItemActive('/PrivilegeSettingToYou')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/PrivilegeSettingToYou">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Privilege Setting</span>
          </NavLink>
        </li>}

        

        

        {pageList.includes("MASTER_PARTICIPANT") &&<li
          className={`menu-item ${getMenuItemActive('/MasterParticipants')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/MasterParticipants">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Master Participants</span>
          </NavLink>
        </li>}

        {pageList.includes("PARTICIPANT") &&<li
          className={`menu-item ${getMenuItemActive('/Participants')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Participants">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Participants</span>
          </NavLink>
        </li>}

        {pageList.includes("USER") &&<li
          className={`menu-item ${getMenuItemActive('/User')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/User">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">User Management</span>
          </NavLink>
        </li>}

        {pageList.includes("ROLE") &&<li
          className={`menu-item ${getMenuItemActive('/Role')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Role">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Role Management</span>
          </NavLink>
        </li>}

        {pageList.includes("REPORT") &&<li
          className={`menu-item ${getMenuItemActive('/Report')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Report">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Report</span>
          </NavLink>
        </li>}

        {/* Components */}
        {/* begin::section */}
      </ul>
      {/* end::Menu Nav */}
    </>
  )
}
