export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken, 
           accessTokenPrivilege
         }
      } = store.getState();
      const accessTokenPrivilegeToken = localStorage.getItem('accessTokenPrivilege')
      const Token = localStorage.getItem('Token')
       var check_privilage = config.url.search("privilege-api");
       var krungthai = config.url.search("krungthai-axa");
       console.log(krungthai)
       localStorage.setItem('krungthai', krungthai)
       //console.log('check_privilage ',check_privilage, accessTokenPrivilegeToken)
       if (check_privilage > 0 && krungthai < 1) {
       config.headers.Authorization = `Bearer ${accessTokenPrivilegeToken}`;
       } else {
      config.headers.Authorization = `Bearer ${Token}`;
       }
      return config;
    },
    err => {
      if (err.response.status === 401) {
        window.location.assign("/logout");
      }
      return Promise.reject(err)
    }
  );
}
