import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {  baseURL } from "../../../service/API.js"
import { useSelector } from "react-redux";
import SVG from 'react-inlinesvg'
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
    toAbsoluteUrl
} from "../../../../_metronic/_helpers"
import FormGroup from '@material-ui/core/FormGroup';

export const EditRole = (props) => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "113188", defaultMessage: "Menu" }));
    const history = useHistory();
    const [data, setData] = useState([]);
    const [test, setTest] = useState([])
    const [order_item, setOrder_item] = useState(1)
    const [titleEN, setTitleEN] = useState('');
    const [role, setRole] = useState('');
    const [descriptionEN, setDescriptionEN] = useState('');
    const [descriptionTH, setDescriptionTH] = useState('');
    const [listPage, setListPage] = useState([]);
    const [groupDeal, setGroupDeal] = useState([]);
    const [listDeal, setListDeal] = useState([]);
    const [bannerList, setbannerList] = useState([]);
    const { user, authToken, accessTokenPrivilege } = useSelector((state) => state.auth);
    const [listGroup2, setListGroup2] = useState([]);
    const [typeList, setTypeList] = useState([
        'VIEW', 'CREATE', 'EDIT', 'DELETE', 'IMPORT', 'EXPORT', 'ADMIN'
    ]);
    const [projectList, setProjectList] = useState([])
    const [isUpload, setIsUpload] = useState(false);
    const [progress, setProgress] = useState(0);

    const [fixedlist, setfixedList] = useState([])
    const [brandList, setBrandtList] = useState([])
    const [is_active, setIs_active] = useState(true);
    const [for_you, setFor_you] = useState(false);
    const [summary, setSummary] = useState([]);
    const [targetlist, setTargetlist] = useState([]);


    const [whiteList, setWhiteList] = useState([])
    const setMapData = (data) => {
        let temp = []
        data.map(item => {
            temp.push({
                key: item.key,
                condition: item.condition,
                value: item.value,
                value_string: item.value_string,
                options: item.options,
                id: item.id,
                showDate: item.showDate,
                showOneDate: item.showOneDate,
                showListValue: item.showListValue,
                showOption: item.showOption,
                showValue: item.showValue,
                groupName: item.groupName,
                listValue: item.listValue,

                selectOption: item.selectOption,
                one_date: item.one_date,
                start_date: item.start_date,
                end_date: item.end_date
            })

        })
        setData(temp)
        setTest(temp)
    }

    const getListPage = async () => {
        let token = localStorage.getItem("Token")
        axios({
            method: "get",
            url: `${baseURL}/api/v1/role/page?time=1&page=1&limit=1000`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            if (res.message) {
            } else {
                setListPage(res.data.data)
                try {
                    let temp_page = []
                    let temp_check = []
                    console.log(' props.location.state.row.permissions',  props.location.state.row)
                    props.location.state.row.permissions.map((item, index)=>{
                        if (!temp_check.includes(item.page.id)) {
                            temp_check.push(item.page.id)
                            let page = res.data.data.filter(x=>x.id === item.page.id)
                            temp_page.push({
                                page: item.page.page_name,
                                page_id: item.page.id,
                                permission: [item.type],
                                listPage: page[0]
                            })
                        } else {
                            var filteredObj = temp_page.findIndex(x=>x.page_id == item.page.id) 
                            console.log('filteredObj', filteredObj)
                            temp_page[filteredObj].permission.push(item.type)
                        }
                        console.log(temp_page)
                    }
                )
                temp_page.sort((a,b) => a.page_id - b.page_id)
                setData(temp_page)
                
                } catch (e) {
                    console.log(e)
                }
            }
        }).catch((err) => {
        });
    };

    const updateFieldChanged = (index, value, name) => {
        let newArr = [...data]; // copying the old datas array
        newArr[index][name] = value; // replace e.target.value with whatever you want to change it to
        setData(newArr)
        
    }
    useEffect(() => {
        getListPage()
        console.log('props', props)
        setRole(props.location.state.row.role)
       
        /*setMapData([{
            page: '',
            page_id: '',
            
            permission: []
        }])*/
    }, []);

    const AlertError = (message) => {
        Swal.fire("Menu Management!", message, "error");
    };

    const onSubmit = async () => {
        if (role == undefined || role == "") {
            return AlertError("Please insert Role");
        }
        console.log('data', data);
        // return false;

        let temp = []
        for (let i in data) {
            if (data[i].page == undefined || data[i].page == '') {
                return AlertError(`Please insert page at permission number ${Number(i) + 1}`);
            }
            if (data[i].permission == [] || data[i].permission.length == 0) {
                return AlertError(`Please insert type at permission number ${Number(i) + 1}`);
            }
            data[i].permission.map((item)=>{
                temp.push({
                    page: data[i].page,
                    page_id: data[i].page_id,
                    type: item,
                })
            })
            console.log(temp)
        }
        var events = {
            role: role,
            permission: temp
        };
        let apiUrls = `${baseURL}/api/v1/role/${props.location.state.row.id}`;
        let token = localStorage.getItem("Token");
        Swal.fire({
            title: "",
            text: 'Confirm Create',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                axios({
                    method: "put",
                    url: apiUrls,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: events,
                })
                    .then(async (res) => {
                        history.goBack();
                    })
                    .catch((err) => {
                        Swal.fire(
                            "Create !",
                            "Your can not create. !! " + err,
                            "error"
                        );
                    });
            }
        })


        return false;
    };

    const addPermission = () => {
        let temp = {
            page: '',
            page_id: '',
            
            permission: []
        }
        setData(data => [...data, temp])
    }

    const DeleteQuestion = (index) => {
        var array = [...data]; // make a separate copy of the array
        array.splice(index, 1);
        setData(array);
    }

    return (
        <>
            <Card>
                <CardHeader title={intl.formatMessage({ id: "113188", defaultMessage: "Create Target Group" })}>
                    <CardHeaderToolbar>
                        {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>

                            </span>
                            {intl.formatMessage({ id: "113178", defaultMessage: "Target Group Name" })}<span style={{ color: "red" }}>
                                *
                            </span>:{" "}
                        </p>
                        <input
                            name="modelGroup"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                        />

                    </div>

                    

                    {data.length == 0 ?
                        <button type="button" className="btn btn-primary mr-2" onClick={() => {
                            addPermission()
                        }}>
                            {intl.formatMessage({ id: "099027", defaultMessage: "ADD" })}
                        </button>
                        : null}


                    {data.map((item, index) => (
                        <Card className="Small shadow" key={index}>
                            <CardHeader title={index + 1}>
                            </CardHeader>
                            <CardBody>
                                <div className="form-inline" style={{ marginBottom: "25px" }}>
                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                                        {intl.formatMessage({ id: "113179", defaultMessage: "Page" })}<span style={{ color: "red" }}>
                                            *
                                        </span>:{" "}
                                    </p>
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={listPage}
                                        getOptionLabel={(option) => option.page_name}
                                        getOptionSelected={(option, value) => option.id == value.id}
                                        value={item.listPage}
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            updateFieldChanged(index, newValue.page_name, 'page')
                                            updateFieldChanged(index, newValue.id, 'page_id')

                                        }}
                                        style={{
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params} variant="outlined" />
                                        }
                                    />
                                </div>

                                <div className="form-inline" style={{ marginBottom: "25px" }}>
                                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>

                                        {intl.formatMessage({ id: "113181", defaultMessage: "Permission type" })}<span style={{ color: "red" }}>
                                            *
                                        </span>:{" "}
                                    </p>
                                    <Autocomplete
                                        id="grouped-demo"
                                        options={typeList}
                                        multiple
                                        //getOptionLabel={(option) => option.label}
                                        //getOptionSelected={(option, value) => option.key == value.key}
                                        value={item.permission}
                                        onChange={(event, newValue) => {
                                            console.log(newValue)
                                            updateFieldChanged(index, newValue, 'permission')
                                        }}
                                        style={{
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params} variant="outlined" />
                                        }
                                    />
                                </div>

                                <CardFooter>
                                    <CardHeaderToolbar>

                                        <button type="button" className="btn btn-danger mr-2" onClick={() => { DeleteQuestion(index) }}>
                                            {intl.formatMessage({ id: "099027", defaultMessage: "DELETE" })}
                                        </button>

                                        {index == data.length - 1 ?
                                            <button type="button" className="btn btn-primary mr-2" onClick={() => {
                                                addPermission()
                                            }}>
                                                {intl.formatMessage({ id: "099027", defaultMessage: "ADD" })}
                                            </button>
                                            : null}
                                    </CardHeaderToolbar>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    ))}

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>
                        <Link to="/Role">
                            <button type="button" className="btn btn-secondary wbt mr-5">
                                {intl.formatMessage({ id: "099065", defaultMessage: "Cancel" })}
                            </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => onSubmit()}>
                            {intl.formatMessage({ id: "099064", defaultMessage: "Save" })}
                        </button>
                    </div>

                </CardBody>
            </Card>
        </>
    );
};
